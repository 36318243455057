
<template>
  <div>
    <el-dialog
      :title="lang_data == 1 ? '选择待寄订单' : 'Select order to be sent'"
      width="700px"
      :visible.sync="waitingShow"
      :before-close="cancelEvent"
      :close-on-click-modal="false"
    >
      <div
        class="waitingorder"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <el-table
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          :data="data"
          border
        >
          <el-table-column
            type="selection"
            :selectable="
              (row) => {
                return row.is_free_send.key == 1 ? true : false;
              }
            "
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="patient_name"
            :label="lang_data == 1 ? '患者' : 'Patient'"
          ></el-table-column>
          <el-table-column
            prop="order_code"
            :label="lang_data == 1 ? '下单码' : 'The order code'"
          ></el-table-column>
          <el-table-column
            prop="order_type.value"
            :label="lang_data == 1 ? '类型' : 'Type'"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEvent" size="small">取消</el-button>
        <el-button type="primary" @click="addOrder" size="small"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "waitingOrder",
  mounted() {
    this.getlist();
  },
  props: {
    waitingShow: [Boolean],
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      data: [],
      multipleSelection: [],
      loading: false,
    };
  },
  methods: {
    getlist() {
      this.loading = true;
      let params = {};
      this.$axios
        .post("/orders/express_free_send_order/getwaitsendorderlist", params)
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.data = res.data;
          }
          this.loading = false;
        });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    addOrder() {
      let params = {
        order_ids: this.multipleSelection.map((item) => {
          return item.id;
        }),
        order_count: this.multipleSelection.length,
      };
      this.$axios
        .post("/orders/express_free_send_order/addqrcode", params)
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.data = res.data;
            this.$emit("ok-event", true);
          }
        });
    },
    okEvent() {
      this.$emit("ok-event", true);
    },
    cancelEvent() {
      this.$emit("cancel-event");
    },
  },
};
</script>
<style lang="scss">
.waitingorder {
  .el-table {
    border: 1px solid #ebeef5;
    border-bottom: 0;
  }
  .el-table td {
    border-bottom: 1px solid #ebeef5;
    padding: 8px 0;
  }
  .cell {
    padding: 0 14px !important;
  }
}
</style>