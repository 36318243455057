<template>
  <div class="code-print-tpl">
    <p class="qr-tip"></p>
    <div class="qr-box">
      <span>{{ clinicName }}</span>
      <div class="qr-code">
        <div id="qrcodetpl"></div>
      </div>
      <span>扫码一键寄送</span>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    clinicName: {
      type: String,
    },
    codeUrl: {
      type: String,
    },
  },
  mounted() {
    this.generateQRCode(this.codeUrl);
    window.print();
    this.$nextTick(() => {
      this.$emit("print-over");
    });
  },
  methods: {
    generateQRCode() {
      new QRCode("qrcodetpl", {
        width: 500,
        height: 500,
        text: this.codeUrl,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.code-print-tpl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
  //   width: 600px;
  //   margin: 0 auto;
  line-height: 30px;
  padding-top: 20%;
  .qr-tip {
    margin: 10px auto;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }
  .qr-box {
    width: 520px;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    span {
      font-size: 25px;
    }
    .qr-code {
      min-width: 500px;
      min-height: 500px;
      // line-height: 200px;
      margin: 10px auto;
      //   border: 1px solid #ddd;
      background-color: #fff; //设置白色背景色
      //   padding: 15px; // 利用padding的特性，挤出白边
      box-sizing: border-box;
    }
  }
}
</style>
