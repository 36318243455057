<template>
  <el-dialog
    :title="lang_data == 1 ? '快递下单' : 'Delivery order'"
    width="700px"
    :visible.sync="confirmShow"
    :before-close="cancel"
    class="confirmOrder"
    :close-on-click-modal="false"
  >
    <div
      class="confirmOrderContent"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      style="min-height: 200px"
    >
      <div v-if="!loading">
        <el-row>
          <span class="circle blue">{{
            lang_data == 1 ? "收" : "Closed"
          }}</span>
          {{ lang_data == 1 ? "收件信息" : "Receipt of information" }}</el-row
        >
        <el-row
          type="flex"
          v-for="(item, index) in data"
          :key="index"
          class="adds-Info"
        >
          <span class="circle">{{ index + 1 }}</span>
          <div>
            {{ item.recipient_true_name
            }}<span>{{ item.recipient_contact }}</span
            ><br />
            {{ item.recipient_province }}{{ item.recipient_city
            }}{{ item.recipient_county }}{{ item.recipient_addr }}
          </div>
        </el-row>

        <el-row>
          <span class="circle pink">{{ lang_data == 1 ? "寄" : "Send" }}</span>
          {{ lang_data == 1 ? "寄件信息" : "Send a message" }}</el-row
        >
        <el-row>
          <el-form
            class="miniSzie"
            ref="form"
            :rules="rules"
            :model="form"
            size="small"
          >
            <el-row type="flex">
              <el-form-item class="mr10" prop="sender_true_name">
                <el-input
                  :placeholder="lang_data == 1 ? '寄件人姓名' : 'The sender'"
                  style="width: 195px"
                  v-model="form.sender_true_name"
                ></el-input>
              </el-form-item>
              <el-form-item prop="sender_contact">
                <el-input
                  style="width: 195px"
                  :placeholder="
                    lang_data == 1 ? '寄件人电话' : 'Sender`s telephone number'
                  "
                  v-model="form.sender_contact"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-form-item>
              <city-select
                ref="citySelect"
                @city-select-change="citySelectChange"
                :county1="form.county"
                :city1="form.city"
                :province1="form.province"
                class="flex-1"
              ></city-select>
            </el-form-item>
            <el-form-item prop="sender_addr">
              <el-input
                :placeholder="
                  lang_data == 1 ? '寄件人地址 ' : 'Address of return'
                "
                v-model="form.sender_addr"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="lang_data == 1 ? '上门时间' : 'The door of time'"
              prop="datetime"
            >
              <deliveryPicker
                v-model="form.datetime"
                @change="sendStartTimeChange"
              ></deliveryPicker>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="small">{{
        lang_data == 1 ? "取消" : "cancel"
      }}</el-button>
      <el-button
        type="primary"
        @click="onConfirm"
        :disabled="Confirmloading"
        size="small"
        >{{ lang_data == 1 ? "确认下单" : "Confirm the order" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import citySelect from "@/components/citySelect";
import deliveryPicker from "./delivery-picker.vue";
export default {
  components: { citySelect, deliveryPicker },
  name: "confirmOrder",
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      data: {},
      form: {
        province: {},
        city: {},
      },
      datetime: "",
      timelist: [],
      loading: true,
      Confirmloading: false,
      rules: {
        sender_true_name: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "姓名不能为空"
                : "The name cannot be empty",
            trigger: "blur",
          },
        ],
        sender_contact: [
          {
            required: true,
            message: localStorage.lang_data == 1 ? "联系方式" : "Contact",
            trigger: "blur",
          },
        ],
        sender_addr: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入详情地址"
                : "Please enter address for details",
            trigger: "blur",
          },
        ],
        datetime: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "上门取货时间不能为空"
                : "Pick-up time cannot be empty",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    ids: {
      type: Array,
    },
    qrcode_count: {
      type: [Number, String],
      default: 0,
    },
    confirmShow: [Boolean],
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.checkaddorder();
      this.getTimelist();
    },
    citySelectChange() {},
    sendStartTimeChange(val) {
      this.form = Object.assign({}, this.form, { datetime: val });
    },
    getTimelist() {
      this.$axios
        .post("/orders/express_free_send_order/getsendstarttime")
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.timelist = res.data;
          }
        });
    },
    checkaddorder() {
      this.loading = true;
      this.$axios
        .post("/orders/express_free_send_order/checkaddorder", {
          express_free_send_qrcode_ids: this.ids,
          qrcode_count: this.qrcode_count,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.data = res.data;
            if (this.data.length > 0) {
              this.form = {
                sender_addr: this.data[0].sender_addr,
                sender_true_name: this.data[0].sender_true_name,
                sender_contact: this.data[0].sender_contact,
                county: {
                  key: this.data[0].sender_county_id,
                  value: this.data[0].sender_county,
                },
                province: {
                  key: this.data[0].sender_province_id,
                  value: this.data[0].sender_province,
                },
                city: {
                  key: this.data[0].sender_city_id,
                  value: this.data[0].sender_city,
                },
              };
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 200);
        });
    },

    onConfirm() {
      this.$refs.form.validate(async (val) => {
        if (val) {
          let data = {
            order_data: this.data,
            send_start_time: this.form.datetime,
          };
          data.order_data.map((item) => {
            item.sender_true_name = this.form.sender_true_name;
            item.sender_addr = this.form.sender_addr;
            item.sender_contact = this.form.sender_contact;
            item.sender_province = this.form.province.value;
            item.sender_city = this.form.city.value;
            item.sender_county = this.form.county.value;
            item.sender_province_id = this.form.province.key;
            item.sender_city_id = this.form.city.key;
            item.sender_county_id = this.form.county.key;
          });

          this.Confirmloading = true;
          // console.log(data)
          // return
          let res = await this.$axios.post(
            "/orders/express_free_send_order/addorder",
            data
          );
          this.Confirmloading = false;
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.$emit("ok-event", true);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel-event");
    },
    okEvent() {
      this.$emit("ok-event");
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.confirmOrder {
  .confirmOrderContent {
    display: flex;
    flex-direction: column;
    .el-row {
      margin-bottom: 20px;
    }
    .miniSzie {
      margin-left: 45px;
      .el-row {
        margin-bottom: 0px;
      }
      .el-form-item--small {
        margin-bottom: 15px;
        line-height: 40px;
      }
    }
    .adds-Info {
      align-items: center;
      div {
        margin-left: 15px;
        background: #f7f8f9;
        line-height: 28px;
        flex: 1;
      }
    }
    .circle {
      border-radius: 50px;
      border: 1px #c2c2c5 solid;
      display: inline-block;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      margin-right: 15px;
      &.blue {
        background: #2143ed;
        color: #fff;
        border: 1px #2143ed solid;
      }
      &.pink {
        background: #e65599;
        color: #fff;
        border: 1px #e65599 solid;
      }
    }
  }
}
</style>
