<template>
  <div
    class="delivery-picker el-input--small"
    :class="{ show: isShow }"
    :value="checkCancel"
  >
    <div class="delivery-input el-input__inner" @click="toggleDrop">
      <template v-if="dateString">{{ dateString }}</template>
      <template v-else>{{
        lang_data == 1 ? "请选择" : "Please select"
      }}</template>
    </div>
    <transition name="slide-top-show">
      <div class="delivery-dropdown">
        <div class="date-list">
          <div
            class="date-item"
            v-for="item in dateList"
            :class="{ active: tempDate == item.date }"
            :key="item.date"
            @click="selectDate(item)"
          >
            <div>{{ item.name }}</div>
            <div>{{ item.date }}</div>
          </div>
        </div>
        <div class="time-list" :class="{ disabled: !this.tempDate }">
          <div
            class="time-item"
            v-for="item in timeList"
            :class="{ active: tempTime == item.key }"
            :key="item.key"
            @click="selectTime(item)"
          >
            {{ item.value }}
          </div>
        </div>
        <!-- <div class="action-group">
                <a href="javascript:void(0)" @click="checkCancel">取消</a>
                <a href="javascript:void(0)" @click="checkOk">确认</a>
            </div> -->
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      isShow: false,
      curDate: "",
      curTime: "",
      tempDate: "",
      tempTime: "",
      dtList: [],
    };
  },
  computed: {
    dateList() {
      return this.dtList;
    },
    timeList() {
      let list = this.dateList.filter((item) => item.date == this.tempDate);
      return list.length > 0 ? list[0].time_list : [];
    },
    dateString() {
      if (this.curDate && this.curTime) {
        let list = [];
        this.dateList.forEach((dateItem) => {
          if (dateItem.date == this.curDate) {
            list = dateItem.time_list.filter(
              (item) => item.key == this.curTime
            );
          }
        });
        let str = "";
        if (list.length > 0) {
          str =
            list[0].key == "in_one_hour"
              ? list[0].value
              : this.curDate + " " + list[0].value;
        }
        return str;
      }
      return "";
    },
  },
  mounted() {
    // if (!this.value) {
    //   this.curDate = this.tempDate = this.dateList[0];
    //   this.curTime = this.tempTime = this.timeList[0];
    // }
    this.getDateList();
  },
  methods: {
    // 获取时间列表
    getDateList() {
      this.$axios
        .get("/orders/express_free_send_order/getsendstarttime")
        .then((res) => {
          if (res.code == "1") {
            this.dtList = res.data;
            if (this.value) {
              if (this.value == "in_one_hour") {
                this.curDate = this.tempDate = this.dtList[0].date;
                this.curTime = this.tempTime = this.value; //curTime保存的是key的值
              } else {
                let date = this.value.split(" ")[0];
                this.curDate = this.tempDate = date;
                this.curTime = this.tempTime = this.value; //curTime保存的是key的值
              }
            } else {
              this.tempDate = this.dtList[0].date;
              this.tempTime =
                this.dtList[0].time_list.length > 0
                  ? this.dtList[0].time_list[0].key
                  : ""; //curTime保存的是key的值
            }
          } else {
            this.$Message.error(res.msg);
          }
        });
    },
    toggleDrop() {
      this.isShow = !this.isShow;
    },
    selectDate(item) {
      this.tempDate = item.date;
      this.tempTime = "";
      // this.$nextTick(() => {
      //   this.tempTime = this.timeList[0];
      // });
    },
    selectTime(item) {
      if (this.tempDate) {
        this.tempTime = item.key;
        this.checkOk();
      }
    },
    checkOk() {
      this.curDate = this.tempDate;
      this.curTime = this.tempTime;
      this.isShow = false;
      this.emitDateString();
    },
    checkCancel() {
      this.tempDate = this.curDate;
      this.tempTime = this.curTime;
      this.isShow = false;
    },
    emitDateString() {
      let dateString = this.dateString;
      // this.curTime != "一小时内"
      //   ? this.curDate + " " + this.curTime
      //   : this.curTime;
      this.$emit("input", this.curTime, dateString);
      this.$emit("change", this.curTime, dateString);
    },
  },
};
</script>
<style lang="scss" scoped>
.delivery-picker {
  float: left;
  position: relative;
  .delivery-input {
    cursor: pointer;
  }
  .delivery-dropdown {
    display: none;
    position: absolute;
    bottom: 100%;
    box-shadow: 1px 1px 5px 2px #ddd;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    width: 500px;
    // min-height: 272px;
    transition: all 0.5s ease-in;
    .date-list {
      display: flex;
      justify-content: flex-start;
      .date-item {
        border: 1px solid #ddd;
        // width: 100px;
        flex: 1;
        padding: 5px;
        margin: 5px;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          border-color: #0086cd;
        }
      }
    }
    .time-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .time-item {
        // border: 1px solid #ddd;
        width: 100px;
        padding: 5px;
        margin: 5px;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          color: #0086cd;
        }
      }
      &.disabled {
        color: #eee !important;
        .time-item {
          &:hover,
          &.active {
            color: #eee;
          }
        }
      }
    }
    .action-group {
      text-align: right;
      //   font-size: 16px;
      padding-right: 30px;
      a {
        color: #0086cd;
        &:hover {
          color: #02a1f5;
        }
      }
    }
  }
  &.show .delivery-dropdown {
    display: block;
  }
}
/*上进下出*/
.slide-top-show-enter-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-timing-function: ease-in-out;
}

.slide-top-show-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
  animation-timing-function: ease-in-out;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
</style>